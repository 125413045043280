import parseStringBoolean from 'utils/common/parse-string-boolean';

export const BASE_API = process.env.REACT_APP_BASE_API || 'https://app.audiorista.com';
export const CONFIG_ID = process.env.REACT_APP_CONFIG_ID || '';
export const PLAYLIST_CAROUSEL_INTERVAL = Number(process.env.REACT_APP_PLAYLIST_CAROUSEL_INTERVAL) || 10000;
export const HISTORY_SAVE_INTERVAL = Number(process.env.REACT_APP_HISTORY_SAVE_INTERVAL) || 10000;
export const PING_INTERVAL = Number(process.env.REACT_APP_PING_INTERVAL) || 30000;
export const INACTIVE_SESSION_LIFE = Number(process.env.REACT_APP_INACTIVE_SESSION_LIFE) || 1800000;
export const CONSOLE_LOGS_ENABLED = parseStringBoolean(process.env.REACT_APP_CONSOLE_LOGS_ENABLED) || false;
export const NODE_ENV = process.env.NODE_ENV || 'development';
export const TREAT_EPUB_AS_PDF = parseStringBoolean(process.env.TREAT_EPUB_AS_PDF) || false;

// Stripe
export const STRIPE_TAX_RATES = process.env.REACT_APP_STRIPE_TAX_RATES;
export const STRIPE_PAYMENT_FUNCTION_NAME = process.env.REACT_APP_STRIPE_PAYMENT_FUNCTION_NAME;
