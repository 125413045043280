import ASSET from 'constants/asset-types';
import { TREAT_EPUB_AS_PDF } from 'constants/env';

export const isAudioContentType = ({ contentType } = {}) => contentType === ASSET.contentType.audio;

export const isVideoContentType = ({ contentType } = {}) => contentType === ASSET.contentType.video;

export const isEbookContentType = ({ contentType } = {}) => contentType === ASSET.contentType.ebook;

// export const isPDFEbookType = ({ mediaType, contentType, downloadType } = {}) =>
//   true;

// export const isEpubEbookType = ({ mediaType, contentType, downloadType } = {}) =>
//   false;

// export const isPubhubEbookType = ({ mediaType, contentType, downloadType } = {}) =>
//   false;

export const isPDFEbookType = ({ mediaType, contentType, downloadType } = {}) =>
  isEbookContentType({ contentType }) &&
  downloadType === ASSET.downloadType.fileUrl &&
  mediaType === ASSET.mediaType.pdf;

export const isEpubEbookType = ({ mediaType, contentType, downloadType } = {}) => false

export const isPubhubEbookType = ({ mediaType, contentType, downloadType } = {}) =>
  isEbookContentType({ contentType }) &&
  downloadType === ASSET.downloadType.fileUrl &&
  mediaType === ASSET.mediaType.epubZip;
